// import CSS
import styles from '../css/app.pcss';

// app main
const main = async () => {

    // google recaptcha
    window.onload = function () {
        const forms = document.querySelectorAll(".recap")
        forms.forEach((form) => {
            form.onsubmit = (ev) => {
                const formtype = ev.target.dataset.type    ?? 'Generic Form'
                const sitekey  = ev.target.dataset.sitekey ?? null

                if( sitekey ) {
                    grecaptcha.ready(function() {
                        try {
                            grecaptcha.execute( sitekey, { action: formtype } ).then(function(token) {
                                let input = document.createElement('input');
                                input.setAttribute("type", "hidden");
                                input.setAttribute("name", "g-recaptcha-response");
                                input.setAttribute("value", token);
                                ev.target.prepend(input);
                                ev.target.submit();
                            });
                        } catch(err) {
                            alert("Problem verifying your submission. Please refresh and try again.")
                        }
                    });
                } else {
                    alert("Problem verifying your submission. Please refresh and try again.")
                }

                return false;
            }
        });
    }

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: '#component-container',

        components: {
            
        },

        data: function() {
            return {
                
            };
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 120 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }
            },
        },

        mounted() {

        },

        created () {
            document.body.classList.add("js-loaded");
            window.addEventListener('scroll', this.handleScroll);
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    })

    return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
